import React from "react";
import axios from "axios";

import "../style/User.css";
import Container from "../component/Container";

export default class MapInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ic: "",
      dialing: "+60",
      contact: "",
      sms: "",

      submitted: false,
      loading: false,

      success: false,
      smsAvailable: props.smsExpiry === false ? false : true,
      smsTime: props.lastSmsTime === 0 ? 0 : 180,

      childInfo: props.childData,
      countryOption: props.dialData,
    };
  }

  componentDidMount() {
    const { lastSmsTime, smsExpiry } = this.props;
    console.log(smsExpiry, lastSmsTime);
    if (!smsExpiry) {
      this.countDownSms();
    }

    console.log(this.props.userData);
  }

  componentWillUnmount() {
    if (!this.state.smsAvailable) {
      // get code clicked
      if (this.state.success) {
        this.props.setLastSmsTime(0);
        this.props.setSmsExpiry(true);
        clearInterval(this.interval);
      } else {
        // clicked & leave
        this.props.setLastSmsTime(this.state.smsTime);
        this.props.setSmsExpiry(this.state.smsAvailable);
        clearInterval(this.interval);
      }
    } else {
      this.props.setLastSmsTime(0);
      this.props.setSmsExpiry(this.state.smsAvailable);
    }
  }

  isValid() {
    if (this.state.ic.length === 0) {
      return false;
    } else if (this.state.contact.length === 0) {
      return false;
    } else if (this.state.sms.length === 0) {
      return false;
    }

    return true;
  }

  async getSmsCode(e) {
    const { userAuth, userData, childData, guid } = this.props;
    console.log(guid);
    e.preventDefault();
    if (this.isValid()) {
      this.setState({ loading: true });
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "Api/Parent/MapChildrenSendSMS",
        {
          Id: childData.id,
          UniqueToken: guid,
          IdentityCardNumber: this.state.ic,
          // ParentEmail: userData.email,
          DialingCode: this.state.dialing,
          ContactNumber: this.state.contact,
        },
        {
          headers: {
            Authorization: "Bearer " + userAuth,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("sms result", response.data);
      if (response.data.result !== null && response.data.result.success) {
        this.setState({ success: true }, () => {
          this.props.setLastSmsTime(0);
          this.props.setSmsExpiry(true);
        });
      } else {
        this.setState({ loading: false, submitted: false }, () => {
          console.log(response.data.result);
          alert(response.data.result.message);
        });
      }
    } else {
      console.log("no data insert");
    }
  }

  countDownSms() {
    const { lastSmsTime } = this.props;
    let newSmsTime = lastSmsTime ? lastSmsTime : 180;
    this.setState({ smsAvailable: false, loading: false });
    this.interval = setInterval(() => {
      newSmsTime--;
      this.setState({ smsTime: newSmsTime });

      if (newSmsTime <= 0) {
        clearInterval(this.interval);
        this.setState({ smsAvailable: true, smsTime: 180 }, () => {
          this.props.setLastSmsTime(0);
          this.props.setSmsExpiry(this.state.smsAvailable);
        });
      } else {
        console.log("timer---> ", newSmsTime);
      }
    }, 1000);
  }

  async onSubmitForm(e) {
    const { userAuth, userData, guid, childData } = this.props;
    e.preventDefault();

    this.setState({ submitted: true });
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "Api/Parent/ChildrenMapCenterWithSMS",
      {
        ChildrenId: childData.id,
        UniqueToken: guid,
        MappingToken: this.state.sms,
        IdentityCardNumber: this.state.ic,
        DialingCode: this.state.dialing,
        ContactNumber: this.state.contact,
      },
      {
        headers: {
          Authorization: "Bearer " + userAuth,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    console.log("submit result", response.data);
    // if (this.isValid()) {
    // let newForm = {
    //   SchoolGuid: guid,
    //   ParentEmail: userData.email,
    //   Name: this.state.name,
    //   IdentityCardNumber: this.state.ic,
    //   GenderId: this.state.gender,
    //   DateOfBirth: this.state.dob,
    // };

    // console.log(newForm);
    // this.addChild();
    // }
  }

  render() {
    const { language } = this.props;
    const { countryOption, childInfo, success } = this.state;
    return (
      <Container {...this.props}>
        {!success ? (
          <div>
            <h3>Map info</h3>
            <div className="container w-75">
              <div className="text-center">
                <img
                  src={require("../asset/default.png")}
                  className="text-center img-logo my-3"
                  alt={"default.png"}
                />
                <p>{childInfo.name}</p>
              </div>
              <form>
                {/* IC */}
                <div className="form-group">
                  <label>
                    {language.ic} <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="identityCard"
                    className={`form-control form-control-md ${
                      this.state.submitted && this.state.ic.length === 0
                        ? "is-invalid"
                        : null
                    }`}
                    // maxLength={12}
                    placeholder={language.enterIc}
                    value={
                      !childInfo.identityCardNumber.includes("0000")
                        ? childInfo.identityCardNumber
                        : this.state.ic
                    }
                    required
                    onChange={(event) =>
                      this.setState({ ic: event.target.value })
                    }
                  />

                  {/* <!-- Validation Error --> */}
                  <div className="invalid-feedback">
                    <div>{language.icRequired}</div>
                  </div>
                </div>
                {/* Contact  */}
                <div className="form-group">
                  <label className="contact-label">
                    {language.contactNumber}
                    <span>*</span>
                  </label>
                  <div className="row">
                    <div className="form-group col-md-5">
                      <select
                        defaultValue=""
                        // value={this.state.contact1Dialing}
                        className={`form-control custom-select ${
                          this.state.submitted && this.state.dialing === ""
                            ? "is-invalid"
                            : null
                        }`}
                        value={this.state.dialing}
                        onChange={(event) =>
                          this.setState({
                            dialing: event.target.value,
                          })
                        }
                      >
                        <option value="" disabled>
                          {language.selectDialingCode}
                        </option>
                        {countryOption.map((item, index) => {
                          return (
                            <option key={index} value={item.dialingCode}>
                              {item.dialingCode}
                            </option>
                          );
                        })}
                      </select>
                      {/* // <!-- Validation Error --> */}
                      <div className="invalid-feedback">
                        <div>{language.selectDialingCode}</div>
                      </div>
                    </div>
                    <div className="form-group col-md-7">
                      <input
                        type="tel"
                        name="contact"
                        className={`form-control form-control-md ${
                          this.state.submitted &&
                          this.state.contact.length === 0
                            ? "is-invalid"
                            : null
                        }`}
                        placeholder={language.enterContact}
                        required
                        value={this.state.contact}
                        onChange={(event) =>
                          this.setState({
                            contact: event.target.value,
                          })
                        }
                      />
                      {/* <!-- Validation Error --> */}
                      <div className="invalid-feedback">
                        <div>{language.contactNumberRequired}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="contact-label">
                    {language.sms}
                    <span>*</span>
                  </label>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <input
                        type="number"
                        name="sms"
                        className={`form-control form-control-md ${
                          this.state.submitted && this.state.sms.length === 0
                            ? "is-invalid"
                            : null
                        }`}
                        placeholder={language.sms}
                        required
                        maxLength="6"
                        min="1"
                        value={this.state.sms}
                        onChange={(event) =>
                          this.setState({
                            sms: event.target.value,
                          })
                        }
                      />
                      {/* <!-- Validation Error --> */}
                      <div className="invalid-feedback">
                        <div>{language.smsRequired}</div>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      {!this.state.loading ? (
                        <button
                          type="button"
                          class="btn-sms"
                          onClick={(e) =>
                            this.state.smsAvailable ? this.getSmsCode(e) : null
                          }
                        >
                          {this.state.smsAvailable
                            ? language.send
                            : `Resend (${this.state.smsTime})`}
                        </button>
                      ) : (
                        <button class="btn-sms" disabled>
                          <i className="fa fa-circle-o-notch fa-spin"></i>
                          {language.loading}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* Button */}
            <div className="row mt-2 ">
              <div className="col-md-12 text-center">
                <button
                  className="btn-next"
                  type="submit"
                  onClick={() => this.props.history.goBack()}
                >
                  {language.back}
                </button>
                {!this.state.loading ? (
                  <button
                    className="btn-next"
                    type="submit"
                    onClick={(event) => this.onSubmitForm(event)}
                  >
                    {language.submit}
                  </button>
                ) : (
                  <button className="btn-next" disabled>
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                    {language.loading}
                  </button>
                )}
              </div>
            </div>
            <br />
            <br />
          </div>
        ) : (
          <div className="bg-white shadow p-5 mb-5">
            <div className="text-center">
              <i
                className="fa fa-check-circle my-3"
                style={{ color: "green", fontSize: 50 }}
              ></i>
              {/* <Lottie options={defaultOptions} height={150} width={150} /> */}
              <p style={{ fontSize: 20 }}>{language.submittedForm}</p>
              <a
                className="btn btn-outline-primary mr-3"
                style={{
                  padding: 10,
                  fontSize: 15,
                }}
                onClick={() => this.props.history.goBack()}
              >
                {language.back}
              </a>

              <a
                href="https://www.ikeyedutech.com.my"
                className="btn btn-outline-secondary ml-3"
                style={{
                  width: 100,
                  padding: 10,
                  fontSize: 15,
                }}
              >
                {language.visitUs}
              </a>
            </div>
          </div>
        )}
      </Container>
    );
  }
}
