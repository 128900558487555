import React from "react";

import "../style/User.css";
import axios from "axios";
import Container from "../component/Container";

export default class ExistUser extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      defaultDate: new Date(),
      dob: new Date(),

      childList: [],
    };
  }

  componentDidMount() {
    this.getChild();

    console.log(window.location.href);
  }

  async getChild() {
    const { userAuth } = this.props;
    console.log(userAuth);
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL + "Api/Parent/Children",
      {
        headers: {
          Authorization: "Bearer " + userAuth,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    console.log(response.data.result);

    if (response.data.result !== null && response.data.result.success) {
      console.log("child - ", response.data.data);
      this.setState({ childList: response.data.data });
    } else {
      this.setState({ loading: false, submitted: false }, () => {
        // alert(response.data.result);
        // console.log(response.data.result, userAuth)
      });
    }
  }

  mapChild(info) {
    const { guid } = this.props;
    this.props.setChildData(info);
    const currentPage = window.location.href.replace(guid, "");

    this.props.history.push("mapinfo/" + guid);

    // window.location.href = currentPage + "mapinfo/" + guid;
  }

  navigateAddChild() {
    const { guid } = this.props;
    const currentPage = window.location.href.replace(guid, "");

    this.props.history.push("addchild/" + guid);
    // window.location.href = currentPage +;
  }

  render() {
    const { language } = this.props;
    return (
      <Container {...this.props}>
        <div>
          <h3>
            Select child
            <br /> to add map info
          </h3>
          <div>
            <div className="scroll-horizontal">
              {this.state.childList
                .filter((item) => {
                  return item.centerName === null;
                })
                .map((i, idx) => {
                  return (
                    <div
                      className="text-center"
                      onClick={() => this.mapChild(i)}
                    >
                      <div
                        className="text-center display-flex"
                        style={{
                          position: "relative",
                          left: "15%",
                          top: "17%",
                          borderWidth: 2,
                          borderRadius: 50,
                          height: 40,
                          width: 40,
                          borderColor: "red",
                          backgroundColor: "red",
                          paddingTop: 5,
                        }}
                      >
                        <i
                          className="fa fa-exclamation"
                          style={{ color: "white", fontSize: 30 }}
                        ></i>
                      </div>
                      <img
                        style={{ cursor: "pointer" }}
                        src={require("../asset/default.png")}
                        className="text-center img-logo"
                        alt={"default.png"}
                      />
                      <p>{i.name}</p>
                    </div>
                  );
                })}
            </div>
          </div>
          {/* Button */}
          <div className="row mt-2 ">
            <div className="col-md-12 text-center">
              {!this.state.loading ? (
                <button
                  className="btn-next"
                  type="submit"
                  onClick={() => this.navigateAddChild()}
                >
                  {language.addNew}
                </button>
              ) : (
                <button className="btn-next" disabled>
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                  {language.loading}
                </button>
              )}
            </div>
          </div>
          <br />
          <br />
        </div>
      </Container>
    );
  }
}

// <div className="bg-white shadow p-5 mb-5">
//             <div className="text-center">
//               <i
//                 className="fa fa-check-circle my-3"
//                 style={{ color: "green", fontSize: 50 }}
//               ></i>
//               {/* <Lottie options={defaultOptions} height={150} width={150} /> */}
//               <p style={{ fontSize: 20 }}>{language.submittedForm}</p>
//               <a
//                 className="btn btn-outline-primary mr-3"
//                 style={{
//                   padding: 10,
//                   fontSize: 15,
//                 }}
//                 onClick={() => this.newSubmission()}
//               >
//                 {language.newSubmission}
//               </a>

//               <a
//                 href="https://www.ikeyedutech.com.my"
//                 className="btn btn-outline-secondary ml-3"
//                 style={{
//                   width: 100,
//                   padding: 10,
//                   fontSize: 15,
//                 }}
//               >
//                 {language.visitUs}
//               </a>
//             </div>
//           </div>
