import React from "react";
import { format } from "react-string-format";
import { Link, useHistory } from "react-router-dom";
import Landing from "../asset/splash.png";
import "../style/User.css";
import axios from "axios";
import Container from "../component/Container";

const localhost = "http://localhost:3000/";
const url_link = "https://api.skoala.com.my/";

export default class NewUser extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      ic: "",
      email: "",
      dialing: "",
      contact: "",
      reEmail: "",
      password: "",
      rePassword: "",
      agreeTnc: false,
      submitted: "",
      countryOption: props.dialData,

      defaultDate: new Date(),
      dob: new Date(),
    };
  }

  isValid() {
    if (this.state.name.length === 0) {
      return false;
    }
    // else if (this.state.ic.length === 0) {
    //   return false;
    // }
    else if (this.state.email.length === 0) {
      return false;
    } else if (this.state.reEmail.length === 0) {
      return false;
    } else if (this.state.reEmail !== this.state.email) {
      return false;
    } else if (this.state.password.length === 0) {
      return false;
    } else if (this.state.rePassword.length === 0) {
      return false;
    } else if (this.state.rePassword !== this.state.password) {
      return false;
    } else if (!this.state.agreeTnc) {
      return false;
    }

    return true;
  }

  async register() {
    const { guid } = this.props;
    console.log("is valid", this.isValid());
    if (this.isValid()) {
      let data = {
        Name: this.state.name,
        IdentityCardNumber: "",
        GenderId: 0,
        RelationshipId: 0,
        Email: this.state.email.trim(),
        Password: this.state.password.trim(),
        DialingCode: this.state.dialing,
        ContactNumber: this.state.contact.trim(),
        Address1: "",
        Address2: "",
        City: "",
        PostalCode: "",
        StateProvince: 0,
        StateProvinceCode: "",
        Country: 0,
        CountryCode: "",
        SchoolGuid: guid,
        AgreeTnC: this.state.agreeTnc,
      };
      // console.log('in second step', this.state.name, this.state.email, this.state.reEmail, this.state.dialing, this.state.contact, this.state.password, this.state.rePassword)
      this.setState({ loading: true, submitted: true });
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "Api/Web/ParentRegister",
        {
          Name: this.state.name,
          IdentityCardNumber: "",
          GenderId: 0,
          RelationshipId: 0,
          Email: this.state.email.trim(),
          Password: this.state.password.trim(),
          DialingCode: this.state.dialing,
          ContactNumber: this.state.contact.trim(),
          Address1: "",
          Address2: "",
          City: "",
          PostalCode: "",
          StateProvince: 0,
          StateProvinceCode: "",
          Country: 0,
          CountryCode: "",
          SchoolGuid: guid,
          AgreeTnC: this.state.checked,
        },
        {
          headers: {
            Authorization: "Bearer ",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("register result", response.data);
      if (response.data.result !== null && response.data.result.success) {
        this.login();
      } else {
        this.setState({ loading: false, submitted: false }, () => {
          alert(response.data.result.message);
        });
      }
    }
  }

  // navigateLogin(id) {
  //   console.log('id',id)
  //   if (id) {
  //     window.location.replace(localhost + '/' + id)
  //   } else {
  //     window.location.replace(localhost)
  //   }
  // }

  async login() {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "Api/Parent/Account/Login",
      {
        PlatformName: "WEB",
        Model: "",
        DeviceId: "",
        Password: this.state.password.trim(),
        Email: this.state.email.trim(),
        FirebaseToken: "",
      }
    );
    console.log("res -", response.data.data);
    if (response.data.result !== null && response.data.result.success) {
      this.setState({ loading: false, submitted: false }, () => {
        this.props.setUserAuth(response.data.data.jwtToken);
        localStorage.setItem("userAuth", response.data.data.jwtToken);
      });
    } else {
      this.setState({ loading: false, submitted: false }, () => {
        alert(response.data.result.message);
      });
    }
  }

  render() {
    const { language } = this.props;
    const { countryOption } = this.state;
    const emailValidate = /[\w-]+@([\w-]+\.)+[\w-]+/;
    return (
      <Container {...this.props}>
        <div>
          {/* <Link to="/register" /> */}
          <h3>Sign Up Skoala</h3>
          <div>
            <form>
              <div className="container-fluid row">
                {/* Left */}
                <div
                  className="col-md-6"
                  // style={{ backgroundColor: 'green', height: '100px' }}
                >
                  {/* Name */}
                  <div className="form-group">
                    <label className="col-form-label pt-0">
                      {language.name} <span>*</span>
                      {/* (As per NRIC) */}
                    </label>
                    <input
                      type="text"
                      name="name"
                      className={`form-control form-control-md ${
                        this.state.submitted && !this.state.name.length > 0
                          ? "is-invalid"
                          : null
                      }`}
                      placeholder={language.enterName}
                      value={this.state.name}
                      required
                      onChange={(event) =>
                        this.setState({ name: event.target.value })
                      }
                    />
                    {/* <!-- Validation Error --> */}
                    <div className="invalid-feedback">
                      <div>{language.nameRequired}</div>
                    </div>
                  </div>
                  {/* Email */}
                  <div className="form-group">
                    <label className="col-form-label pt-0">
                      {language.email} <span>*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      className={`form-control form-control-md ${
                        this.state.submitted &&
                        this.state.email.length > 0 &&
                        !emailValidate.test(this.state.email)
                          ? "is-invalid"
                          : null
                      }`}
                      placeholder={language.enterEmail}
                      required
                      value={this.state.email}
                      onChange={(event) => {
                        this.setState({ email: event.target.value });
                      }}
                    />
                    {/* <!-- Validation Error --> */}
                    <div className="invalid-feedback">
                      {this.state.email.length > 0 &&
                        !emailValidate.test(this.state.email) && (
                          <div>{language.emailIncorrect}</div>
                        )}
                    </div>
                  </div>
                  {/* Password */}
                  <div className="form-group">
                    <label className="col-form-label pt-0">
                      {language.password} <span>*</span>
                      {/* (As per NRIC) */}
                    </label>
                    <input
                      type="text"
                      name="password"
                      className={`form-control form-control-md ${
                        this.state.submitted && !this.state.password.length > 0
                          ? "is-invalid"
                          : null
                      }`}
                      placeholder={"Enter Password"}
                      value={this.state.password}
                      required
                      onChange={(event) =>
                        this.setState({ password: event.target.value })
                      }
                    />
                    {/* <!-- Validation Error --> */}
                    <div className="invalid-feedback">
                      <div>{"nameRequired"}</div>
                    </div>
                  </div>
                </div>
                {/* Right */}
                <div
                  className="col-md-6"
                  // style={{ backgroundColor: 'yellow', height: '100px' }}
                >
                  {/* // <!-- Dialing Code + Contact --> */}
                  <label className="contact-label">
                    {language.contactNumber}
                    <span>*</span>
                  </label>
                  <div className="row">
                    <div className="form-group col-md-5">
                      <select
                        defaultValue=""
                        // value={this.state.contact1Dialing}
                        className={`form-control custom-select ${
                          this.state.submitted && this.state.dialing === ""
                            ? "is-invalid"
                            : null
                        }`}
                        value={this.state.dialing}
                        onChange={(event) =>
                          this.setState({
                            dialing: event.target.value,
                          })
                        }
                      >
                        <option value="" disabled>
                          {language.selectDialingCode}
                        </option>
                        {countryOption.map((item, index) => {
                          return (
                            <option key={index} value={item.dialingCode}>
                              {item.dialingCode}
                            </option>
                          );
                        })}
                      </select>
                      {/* // <!-- Validation Error --> */}
                      <div className="invalid-feedback">
                        <div>{language.selectDialingCode}</div>
                      </div>
                    </div>
                    <div className="form-group col-md-7">
                      <input
                        type="tel"
                        name="contact"
                        className={`form-control form-control-md ${
                          this.state.submitted &&
                          this.state.contactPhone.length === 0
                            ? "is-invalid"
                            : null
                        }`}
                        placeholder={language.enterContact}
                        required
                        value={this.state.contactPhone}
                        onChange={(event) =>
                          this.setState({
                            contact: event.target.value,
                          })
                        }
                      />
                      {/* <!-- Validation Error --> */}
                      <div className="invalid-feedback">
                        <div>{language.contactNumberRequired}</div>
                      </div>
                    </div>
                  </div>

                  {/* Confirm Email */}
                  <div className="form-group">
                    <label className="col-form-label pt-0">
                      {language.confirmEmail}
                      <span>*</span>
                    </label>
                    <input
                      type="email"
                      name="confirmEmail"
                      className={`form-control form-control-md ${
                        this.state.submitted &&
                        this.state.reEmail.length > 0 &&
                        !emailValidate.test(this.state.reEmail) &&
                        this.state.email !== this.state.reEmail
                          ? "is-invalid"
                          : null
                      }`}
                      placeholder={language.enterEmail}
                      required
                      value={this.state.reEmail}
                      onChange={(event) => {
                        this.setState({ reEmail: event.target.value });
                      }}
                    />
                    {/* <!-- Validation Error --> */}
                    <div className="invalid-feedback">
                      {this.state.email.length > 0 &&
                        !emailValidate.test(this.state.email) && (
                          <div>{language.emailIncorrect}</div>
                        )}
                    </div>
                  </div>

                  {/* Confirm Password */}
                  <div className="form-group">
                    <label className="col-form-label pt-0">
                      {language.confirmPassword} <span>*</span>
                      {/* (As per NRIC) */}
                    </label>
                    <input
                      type="text"
                      name="confirmPassword"
                      className={`form-control form-control-md ${
                        this.state.submitted &&
                        !this.state.rePassword.length > 0 &&
                        this.state.password !== this.state.rePassword
                          ? "is-invalid"
                          : null
                      }`}
                      placeholder={"Enter Password"}
                      value={this.state.rePassword}
                      required
                      onChange={(event) =>
                        this.setState({ rePassword: event.target.value })
                      }
                    />
                    {/* <!-- Validation Error --> */}
                    <div className="invalid-feedback">
                      <div>{"nameRequired"}</div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- tnc --> */}
              <div className="form-check mt-3 mb-3 text-center">
                <input
                  type="checkbox"
                  className={`form-check-input ${
                    this.state.submitted && !this.state.agreeTnc
                      ? "is-invalid"
                      : null
                  }`}
                  id="ikeyTnc"
                  name="agreeTnc"
                  onChange={(event) =>
                    this.setState({ agreeTnc: event.target.checked })
                  }
                />
                <label className="form-check-label" for="ikeyTnc">
                  {format(language.agreeTo, "Smartedu Innovation Sdn Bhd")}
                  <a
                    href="https://skoala.com.my/terms"
                    className="tncLink"
                    target="_blank"
                  >
                    {" " + language.termUse}
                  </a>
                  {" " + language.and + " "}
                  <a
                    href="https://skoala.com.my/privacy-policy"
                    className="tncLink"
                    target="_blank"
                  >
                    {language.privacyStatement + " "}
                  </a>
                  {language.ofIkey}
                  <span> *</span>
                </label>
                {/* <!-- Validation Error --> */}
                <div className="invalid-feedback">
                  <div>{language.agreeTncRequired}</div>
                </div>
              </div>
            </form>
            {/* Button */}
            <div className="row mt-2 ">
              <div className="col-md-12 text-center">
                <button
                  className="btn-next"
                  type="submit"
                  onClick={() => this.props.history.goBack()}
                >
                  {language.back}
                </button>
                {!this.state.loading ? (
                  <button
                    className="btn-next"
                    type="submit"
                    onClick={(event) => this.register(event)}
                  >
                    {language.next}
                  </button>
                ) : (
                  <button className="btn-next" disabled>
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                    {language.loading}
                  </button>
                )}
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </Container>
    );
  }
}
