import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ExistUser from "./ExistUser";
import NewUser from "./NewUser";
import { Link } from "react-router-dom";

import axios from "axios";

import "../style/User.css";
import Container from "../component/Container";

const localhost = "http://localhost:3000/";
const url_link = "https://api.skoala.com.my/";

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      part: 1,

      successPop: false,
      loading: false,
    };
  }

  async forgotPassword() {
    this.setState({ loading: true });
    const { userAuth } = this.props;
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "Api/Parent/ForgetPassword",
      {
        Email: this.state.email,
      },
      {
        headers: {
          Authorization: "Bearer " + userAuth,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    console.log("foret password", response.data);
    if (response.data.result !== null && response.data.result.success) {
      this.setState({ smsAvailable: false, loading: false }, () => {
        alert("Reset password link had been sent to your email");
      });
    } else {
      this.setState({ loading: false, submitted: false }, () => {
        alert(response.data.result.message);
      });
    }
  }

  navigateLogin(id) {
    console.log("id", id);
    if (id) {
      window.location.replace(localhost + "/" + id);
    } else {
      window.location.replace(localhost);
    }
  }

  render() {
    const { language, userAuth } = this.props;
    return (
      <Container {...this.props}>
        <div className="login">
          <div className="login-form">
            <div className="forgot-content">
              <i className="fa fa-lock"></i>
              <Link to="/forgotpassword" />
              <h3>Forgot Password ?</h3>
              <h6>We will send you an email to reset your password</h6>
            </div>
            <form>
              <label>{language.email}</label>
              <input
                type="email"
                name="email"
                className={`form-control form-control-md`}
                value={this.state.email}
                placeholder={language.email}
                required
                onChange={(event) =>
                  this.setState({ email: event.target.value })
                }
              />
            </form>

            {/* Button */}
            <div className="row mt-2 ">
              <div className="col-md-12 text-center">
                {!this.state.loading ? (
                  <button
                    className="btn-next"
                    type="submit"
                    onClick={() => this.props.history.goBack()}
                  >
                    {language.back}
                  </button>
                ) : (
                  <button className="btn-next" disabled>
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                    {language.loading}
                  </button>
                )}
                {!this.state.loading ? (
                  <button
                    className="btn-next"
                    type="submit"
                    onClick={() => this.forgotPassword()}
                  >
                    {language.submit}
                  </button>
                ) : (
                  <button className="btn-next" disabled>
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                    {language.loading}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}
