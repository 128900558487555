import React from "react";

import Landing from "../asset/splash.png";
import axios from "axios";

export default class Container extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeLanguage: "English",
      languageId: "en",

      isOpen: false,
    };

    this.selection = [
      { id: "en", name: "English" },
      { id: "zh", name: "中文" },
      { id: "my", name: "Malay" },
      { id: "jp", name: "日本語" },
    ];
  }

  componentDidMount() {
    this.props.setLanguage("en");
  }

  switchLanguage = (id) => {
    let currentLanguage = this.selection
      .filter((item) => {
        return item.id === id;
      })
      .map((i) => {
        return i.name;
      });
    // console.log(currentLanguage);
    this.setState({ activeLanguage: currentLanguage, languageId: id }, () => {
      this.props.setLanguage(id);
    });
  };

  async logout() {
    const { userAuth, guid, selection } = this.props;
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "Api/Parent/Account/Logout",
      {},
      {
        headers: {
          Authorization: "Bearer " + userAuth,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.result !== null && response.data.result.success) {
      this.setState({ loading: false }, () => {
        this.props.setUserAuth("");
        localStorage.clear();

        // const currentPage = window.location.href.replace(guid, "");
        this.props.history.push("/" + guid);
        // window.location.href = window.location.host + "/" + guid;
      });
    } else {
      this.setState({ loading: false }, () => {
        alert(response.data.result.message);
      });
    }
  }
  render() {
    const { userAuth, loading, language } = this.props;
    const { isOpen } = this.state;
    return (
      <div className="row">
        <div className="flex col-md-6">
          <img src={Landing} className="landing" alt={"landing.png"} />
        </div>

        <div className="container col-md-6">
          <div className="container pt-3">
            <button
              className="btn-logout dropdown"
              style={{
                position: "absolute",
                right: 50,
              }}
              onClick={() => this.setState({ isOpen: !isOpen })}
            >
              <i className="fa fa-sliders"></i>
              {isOpen ? (
                <ul className="menu">
                  {this.selection.map((item, index) => {
                    return (
                      <li
                        className={
                          this.state.activeLanguage.toString() !== item.name
                            ? "menu-item"
                            : "menu-selected"
                        }
                      >
                        <button
                          key={index}
                          onClick={() => this.switchLanguage(item.id)}
                        >
                          {item.name}
                        </button>
                      </li>
                    );
                  })}
                  {userAuth ? (
                    <>
                      <div
                        style={{
                          height: 1,
                          backgroundColor: "lightblue",
                          marginLeft: 5,
                          marginRight: 5,
                          marginTop: 5,
                        }}
                      />
                      <li className="logout-item">
                        <button onClick={() => this.logout()}>
                          {loading ? (
                            <i className="fa fa-circle-o-notch fa-spin"></i>
                          ) : (
                            language.logout
                          )}
                        </button>
                      </li>
                    </>
                  ) : null}
                </ul>
              ) : null}
            </button>
          </div>

          <div className="main-content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
