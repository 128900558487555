import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Provider, connect } from "react-redux";
import store, {
  setLanguage,
  setDialData,
  setFormData,
  setChildData,
  setUserAuth,
  setUserData,
  setGuid,
  setLastSmsTime,
  setSmsExpiry,
} from "./redux/app-redux";

import ForgotPassword from "./screens/ForgotPassword";
import Login from "./screens/Login";
import NewUser from "./screens/NewUser";
import Container from "./component/Container";
import AddChild from "./screens/AddChild";
import MapInfo from "./screens/MapInfo";
import ExistUser from "./screens/ExistUser";

const mapStateToProps = (state) => {
  return {
    language: state.language,
    dialData: state.dialData,
    formData: state.formData,
    childData: state.childData,
    userData: state.userData,
    userAuth: state.userAuth,
    //Sms
    smsExpiry: state.smsExpiry,
    lastSmsTime: state.lastSmsTime,

    guid: state.guid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (language) => dispatch(setLanguage(language)),
    setDialData: (dialData) => dispatch(setDialData(dialData)),
    setFormData: (formData) => dispatch(setFormData(formData)),
    setChildData: (childData) => dispatch(setChildData(childData)),
    setUserData: (userData) => dispatch(setUserData(userData)),
    setUserAuth: (userAuth) => dispatch(setUserAuth(userAuth)),

    //Sms
    setSmsExpiry: (smsExpiry) => dispatch(setSmsExpiry(smsExpiry)),
    setLastSmsTime: (lastSmsTime) => dispatch(setLastSmsTime(lastSmsTime)),

    setGuid: (guid) => dispatch(setGuid(guid)),
  };
};

function Main() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/:id"
          component={connect(mapStateToProps, mapDispatchToProps)(Login)}
        />
        <Route
          exact
          path="/register/:id"
          component={connect(mapStateToProps, mapDispatchToProps)(NewUser)}
        />
        <Route
          exact
          path="/forgotpassword/:id"
          component={connect(
            mapStateToProps,
            mapDispatchToProps
          )(ForgotPassword)}
        />
        <Route
          exact
          path="/user/:id"
          component={connect(mapStateToProps, mapDispatchToProps)(ExistUser)}
        />
        <Route
          exact
          path="/user/mapinfo/:id"
          component={connect(mapStateToProps, mapDispatchToProps)(MapInfo)}
        />
        <Route
          exact
          path="/user/addchild/:id"
          component={connect(mapStateToProps, mapDispatchToProps)(AddChild)}
        />
      </Switch>
    </Router>
  );
}

export default Main;
