const zh_CN = {
  almostThere: '差不多了！ 您还有其他问题要回答。',
  continue: '继续',
  visitUs: '更多详情',
  newSubmission: '提交新的报名表格',
  termUse: '使用条款',
  perviousPage: '上一页',
  chooseInterestCource: '请选择有兴趣的课程',
  ignoreCourse: '课程(不相关请忽略)',
  hearAboutUs: '您从哪里得知我们?',
  hearAboutPlaceholder: '例如：Facebook',
  agreeTo: '我同意{0}的',
  and: '和',
  privacyStatement: '隐私政策',
  ofIkey: '',
  acceptTnC: '请接受使用条款和隐私政策',
  agree: '我同意{0}的',
  termCondition: '规则与条例',
  of: '',
  submit: '提交',
  page: '{0} 页',

  learnerInfo: '学生资料',
  profile: '个人头像',
  name: '名字',
  enterName: '请输入您的名字',
  gender: '性别',
  chooseGender: '请选择性别',
  male: '男性',
  female: '女性',
  notSpecify: 'Not Specify',
  dob: '出生日期',
  selectDob: '请选择出生日期',
  ic: '出生证号/护照号/身份证号',
  enterIc: '请输入出生证号/护照号/身份证号',
  contactIc: '护照号/身份证号',
  enterContactIc: '请输入护照号/身份证号',
  birthCert: '报生纸号码',
  enterbirthCert: '请输入报生纸号码',
  schoolName: '学校名字',
  enterSchoolName: '请输入学校名字',
  standard: '班级 / 等级 / 级别',
  selectStandard: '请选择 班级 / 等级 / 级别',
  unit: '住宅编号',
  enterUnit: '请输入住宅编号 例如: No 12-Z',
  streetName: '住宅街名',
  enterStreet: '请输入住宅街名',
  city: '城市',
  enterCity: '请输入城市',
  postalCode: '邮编号码',
  enterPostal: '请输入邮编号码 例如: 12345',
  country: '国家',
  selectCountry: '请选择国家',
  state: '州属 / 省份',
  selectState: '请选择州属 / 省份',
  medicalCondition: '医疗状况/过敏',
  childSpecialInfo: '有任何关于孩子的特殊情况想让我们知道？',
  medicalPlaceholder: '例如: 花生过敏',
  next: '下一页',
  prev: '上一页',
  sameAbove: '同上',
  contactPerson1: '联络人 1 (必须)',
  email: '电子邮件',
  enterEmail: '请输入电子邮件',
  relationship: '关系',
  selectRelationship: '请选择与学生的关系',
  contactNumber: '联络号码',
  enterContact: '请输入联络号码 例如:123456789',
  dialingCode: '拨号代码',
  selectDialingCode: '请选择拨号代码',
  race: '种族',
  enterRace: '请输入种族',
  spoken: '母语',
  enterSpoken: '请输入母语',
  siblingNo: '兄弟姐妹的人数',
  enterSibling: '请输入兄弟姐妹的人数',
  position: '兄弟姐妹排位',
  enterPosition: '请输入兄弟姐妹排位',
  placeBirth: '出生地点',
  enterPlaceBirth: '请输入出生地点',
  nation: '国籍',
  enterNation: '请输入国籍',
  companyName: '公司名称',
  enterCompany: '请输入公司名称',
  religion: '宗教信仰',
  enterReligion: '请输入宗教信仰',
  marital: '婚姻状况',
  enterMarital: '请输入婚姻状况',
  job: '职业',
  enterJob: '请输入职业',
  vehicle: '座驾编号',
  enterVehicle: '请输入座驾编号',
  primaryContact: '主要联系人',
  contactPerson2: '联络人 {0}',
  moreContact: '添加联系人',
  enterAnswer: '请输入答案',
  loading: '处理中',
  enrollmentForm: '学生线上报名表格',
  submittedForm: '报名表格已成功提交',
  anotherPage: '最后一步了...',

  //Relationship
  auntie: '阿姨',
  caregiver: '监护人',
  driver: '司机',
  emergencyContact: '紧急联系人',
  father: '爸爸',
  grandfather: '爷爷',
  grandmother: '奶奶',
  mother: ' 妈妈',
  nanny: '保姆',
  notSpecify: '不指定',
  spouse: '配偶',
  studentself: '学生本人',
  uncle: '叔叔',

  //Error Message
  nameRequired: 'Student Name is required',
  genderRequired: '请选择 a gender',
  dobRequired: 'Date of Birth is required',
  icRequired: 'IC no. is required',
  icNotCorrect: 'IC no. not correct',
  unitRequired: '请输入unit no.',
  streetRequired: ' 请输入street name',
  postalCodeRequired: 'Postal code is required',
  countryRequired: '请选择 a country',
  stateRequired: '请选择 State / Province',
  emailIncorrect: '请输入Correct Email',
  emailRequired: 'Email is required',
  relationshipRequired: 'Relationship is required',
  dialingCodeRequired: 'Dialing code is required',
  contactNumberRequired: 'Contact number is required',
  agreeTncRequired: '请接受Smartedu Innovation Sdn Bhd的使用条款和隐私政策',
  agreeCenterRequired: '请同意{0}的规则与条例',

  //Directory

  // Center
  learnerAge: 'Learner Age',
  relatedCourse: '相关课程',
  webiste: 'Website',
  openHours: 'Open Hours',
  fullfillRequirement: 'Fullfill your requirement?',
  address: 'Address',
  nextSection: 'Next Section (Compulsory)',
  course: 'Course',

   // login
   login: '登入',
   signUp: '注册',
   password: '密码',
   forgetPw: '忘记密码?',
   sms: 'SMS Code',
   send: 'Get Code',
   addNew: 'Add New Learner',
   back: 'Back',
   logout: '登出',
};



export default zh_CN;
