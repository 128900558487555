import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import axios from "axios";

import "../style/User.css";
import Container from "../component/Container";

import { Link } from "react-router-dom";

const localhost = "http://localhost:3000/";
const url_link = "https://api.skoala.com.my/";
const emailValidate = /[\w-]+@([\w-]+\.)+[\w-]+/;

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      part: 1,

      successPop: false,
      loading: false,

      hideUpperBtn: true,
    };
  }
  componentDidMount() {
    this.props.setLanguage("en");
    console.log({ ...this.props });
    if (this.props.match.params.id) {
      this.props.setGuid(this.props.match.params.id);
    }
    if (this.props.userAuth) {
      // const currentPage = window.location.href;
      this.props.history.push("/user");
      // window.location.href = currentPage + ;
    } else {
      localStorage.clear();
    }
    this.getDialData();
  }

  async getDialData() {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL + "Api/Countries/",
      {
        headers: {
          Authorization: "Bearer ",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.result !== null && response.data.result.success) {
      this.props.setDialData(response.data.data);
    }
  }

  toggleTnc() {
    this.setState({ successPop: true });
  }

  isValid() {
    if (this.state.email === "") {
      return false;
    } else if (!emailValidate.test(this.state.email)) {
      return false;
    } else if (this.state.password === " ") {
      return false;
    }

    return true;
  }

  async login() {
    const { guid } = this.props;
    if (this.isValid()) {
      this.setState({ loading: true });
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "Api/Parent/Account/Login",
        {
          PlatformName: "WEB",
          Model: "",
          DeviceId: "",
          Password: this.state.password.trim(),
          Email: this.state.email.trim(),
          FirebaseToken: "",
        },
        {
          headers: {
            Authorization: "Bearer ",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.result !== null && response.data.result.success) {
        console.log("res -", response.data.data);
        this.props.setUserAuth(response.data.data.jwtToken);
        this.props.setUserData(response.data.data);
        localStorage.setItem("userAuth", response.data.data.jwtToken);
        this.setState({ loading: false, submitted: false }, () => {
          // console.log('setUserData', this.props.userData.userId)

          this.props.history.push("user/" + guid);
          // const currentPage = window.location.href.replace(guid, "");

          // window.location.href = currentPage + "user/" + guid;
        });
      } else {
        this.setState({ loading: false, submitted: false }, () => {
          alert(response.data.result.message);
        });
      }
    }
  }

  // async logout() {
  //   const { userAuth } = this.props;
  //   const response = await axios.post(
  //     process.env.REACT_APP_BASE_URL + "Api/Parent/Account/Logout",
  //     {},
  //     {
  //       headers: {
  //         Authorization: "Bearer " + userAuth,
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   );

  //   if (response.data.result !== null && response.data.result.success) {
  //     this.setState({ loading: false }, () => {
  //       this.props.setUserAuth("");
  //       localStorage.clear();
  //       this.switchPage(1);
  //     });
  //   } else {
  //     this.setState({ loading: false }, () => {
  //       alert(response.data.result.message);
  //     });
  //   }
  // }

  hideButton() {
    this.setState({ hideUpperBtn: !this.state.hideUpperBtn });
  }

  render() {
    const { language, userAuth, guid } = this.props;
    const { part, loading } = this.state;
    console.log(window.location.href);
    return (
      <Container {...this.props}>
        <Modal isOpen={this.state.modalTnc} toggle={this.toggleTnc.bind(this)}>
          <ModalHeader toggle={this.toggleTnc.bind(this)}>
            SJK (T) Thamboosamy Pillay
          </ModalHeader>
          <ModalBody style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}>
            You are now interacting with 3rd party School App
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleTnc()}>
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <div className="login">
          <div className="login-form">
            <form>
              <label>{language.email}</label>
              <input
                type="email"
                name="email"
                className={`form-control form-control-md`}
                value={this.state.email}
                placeholder={language.email}
                required
                onChange={(event) =>
                  this.setState({ email: event.target.value })
                }
              />

              <label>{language.password}</label>
              <input
                type="password"
                name="password"
                className={`form-control form-control-md`}
                value={this.state.password}
                placeholder={language.password}
                required
                onChange={(event) =>
                  this.setState({ password: event.target.value })
                }
              />
            </form>

            <Link className="forgot-container" to={"/forgotpassword/" + guid}>
              {language.forgetPw}
            </Link>
            {/* Button */}
            {!userAuth ? (
              <div className="row mt-2 ">
                <div className="col-md-12 text-center">
                  {!this.state.loading ? (
                    <button
                      className="btn-next"
                      type="submit"
                      onClick={() => this.login()}
                    >
                      {language.login}
                    </button>
                  ) : (
                    <button className="btn-next" disabled>
                      <i className="fa fa-circle-o-notch fa-spin"></i>
                      {language.loading}
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className="row mt-2 ">
                <div className="col-md-12 text-center">
                  {!this.state.loading ? (
                    <button
                      className="btn-next"
                      type="submit"
                      onClick={() => this.logout()}
                    >
                      {language.logout}
                    </button>
                  ) : (
                    <button className="btn-next" disabled>
                      <i className="fa fa-circle-o-notch fa-spin"></i>
                      {language.loading}
                    </button>
                  )}
                </div>
              </div>
            )}

            {/* Button */}
            <div className="row mt-2 ">
              <div className="col-md-12 text-center">
                {!this.state.loading ? (
                  <Link to={"/register/" + guid}>
                    <button className="btn-next" type="submit">
                      {language.signUp}
                    </button>
                  </Link>
                ) : (
                  <button className="btn-next" disabled>
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                    {language.loading}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}
